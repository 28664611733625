export default class VehicleDisposalPermit {
	constructor(dto) {
		this.wasteDisposalSiteId = null;
		this.permitNumber = '';

		if (typeof dto === 'object' && dto !== null) {
			if (typeof dto.wasteDisposalSiteId === 'number') {
				this.wasteDisposalSiteId = dto.wasteDisposalSiteId;
			}
			if (typeof dto.permitNumber === 'string') {
				this.permitNumber = dto.permitNumber;
			}
		}
	}
}

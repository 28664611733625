import VehicleDisposalPermit from './VehicleDisposalPermit.js';
import VehicleType from './VehicleType.js';
import { getArrayOfObjectsFromDto as getTypedArray, getDateTimeFromDto as getDate, getObjectFromDto as getObject, getValueFromDto as getValue } from './_helpers.js';

export default class Vehicle {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		this.number = getValue(dto, 'number', 'number', null);
		this.name = getValue(dto, 'name', 'string', '');
		this.year = getValue(dto, 'year', 'string', '');
		this.make = getValue(dto, 'make', 'string', '');
		this.model = getValue(dto, 'model', 'string', '');
		this.vehicleTypeId = getValue(dto, 'vehicleTypeId', 'number', null);
		this.vehicleType = getObject(dto, 'vehicleType', VehicleType, null);
		Object.defineProperty(this, 'vehicleType', { enumerable: false });
		this.tankCapacity = getValue(dto, 'tankCapacity', 'number', 0);
		this.registrationNumber = getValue(dto, 'registrationNumber', 'string', '');
		this.vehicleIdentificationNumber = getValue(dto, 'vehicleIdentificationNumber', 'string', '');
		this.licensePlateNumber = getValue(dto, 'licensePlateNumber', 'string', '');
		this.licenseState = getValue(dto, 'licenseState', 'string', '');
		this.purchaseDate = getDate(dto, 'purchaseDate', null);
		this.mileage = getValue(dto, 'mileage', 'number', 0);
		this.hours = getValue(dto, 'hours', 'number', 0);
		this.notes = getValue(dto, 'notes', 'string', '');
		this.active = getValue(dto, 'active', 'boolean', true);
		this.disposalPermits = getTypedArray(dto, 'disposalPermits', VehicleDisposalPermit, []);
		Object.defineProperty(this, 'maintenanceSchedule', { enumerable: false, value: [], writable: true });
	}

	get maintenanceScheduleReminders() {
		return this.maintenanceSchedule.filter(x => x.isPastWarning);
	}
}
